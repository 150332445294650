.scroll{
    width: 240px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    background-color: rgba(249, 249, 249, 1);
}
::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius   : 4px;
    background-color: rgba(153, 153, 153, 1);
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
}
::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(255, 255, 255, 1);
    background   : #fff;
    border-radius: 10px;
}

body{
    width: 100%;
    height: 100%;
    display: flex;
}
.home-header{
    height: 80px !important;
    background-color: #1890ff !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    line-height: 80px !important;
}

.home-content-left{
    width: 240px;
background-color: #fff !important;
}
.home-content-right {
    flex:1
}

.ant-menu-item-selected{
    background-color: #e6f7ff !important;
}

.ant-menu-item-selected span{
    color: #1890ff !important;
}

.ant-table-wrapper {
    background-color: #fff !important;
}